:root {
    --primary-color: #910946;
    --secondary-color: #910946;
    --accent-color: #f2f2f2;
    --light-background: #fff;
    --text-color: #000;
    --hover-effect: #f2f2f2;
    --button-text: #ffffff;
}

*,
*::after,
*::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;

}

body {
    background: linear-gradient(135deg, var(--primary-color) 0%, var(--secondary-color) 100%);
    color: var(--text-color);
    line-height: 1.6;
    font-size: 16px;
    font-family: 'Times New Roman', Times, serif;
}

.hidden {
    display: none;
}

.warning {
    font-size: 12px;
    color: var(--text-hover);
}

.modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(50, 50, 50, 0.85);
    z-index: 999;
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--light-background);
    padding: 40px;
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
    text-align: center;
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
}

.modal_txt {
    font-size: 22px;
    margin-bottom: 20px;
    color: var(--text-color);
}

.modal_btns {
    display: flex;
    gap: 20px;
    justify-content: center;
}

.modal_btn {
    background-color: var(--accent-color);
    color: var(--button-text);
    border: none;
    padding: 12px 30px;
    border-radius: 50px;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    font-weight: bold;
}

.modal_btn:hover {
    background-color: var(--hover-effect);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.hidden {
    display: none;
}


.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.header {
    background-color: var(--secondary-color);
    color: #fff;
    padding: 10px 0;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}

.header-logo a {
    font-size: 24px;
    color: #fff;
    text-decoration: none;
    font-weight: bold;
}

.header-menu {
    list-style: none;
    display: flex;
    gap: 20px;
}

.header-menu a {
    color: #fff;
    text-decoration: none;
    padding: 5px 15px;
    transition: color 0.3s;
}

.header-menu a:hover {
    color: var(--hover-effect);
}

.main {
    padding-top: 5rem;
}

.promo {
    padding: 40px 0;
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url(../public/promo.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: cover;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin: 20px;
    transition: all 0.3s ease-in-out;
    color: #fff;
}

.promo:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.promo-title {
    font-size: 32px;
    color: #fff;
}

.promo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.promo-content {
    padding: 20px;
}

.promo-txt {
    font-size: 18px;
    margin: 20px 0;
}

.promo-img-container {
    position: relative;
    text-align: center;
    border-radius: 10px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.promo-img-container img {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease-in-out;
    border-radius: 10px;
}

.promo-btn {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--secondary-color);
    color: var(--light-text);
    padding: 12px 30px;
    font-size: 18px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    transition: background-color 0.3s, color 0.3s, transform 0.3s;
}

.promo-btn:hover {
    background-color: var(--accent-color);
    color: var(--primary-color);
    transform: translateY(-5px) translateX(-50%);
}


.game {
    background-color: var(--light-background);
    padding: 50px 0;
    background-color: #252525;
}

.game-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    align-items: start;
}

.game-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: var(--light-background);
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
    height: 100%;
    text-decoration: none;
}

.game-container img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.game-container:hover {
    transform: scale(1.02);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.game-container:hover img {
    transform: scale(1.1);
}

.game-txt {
    background-color: var(--secondary-color);
    color: #fff;
    width: 100%;
    padding: 10px;
    font-size: 24px;
    box-sizing: border-box;
    margin-top: auto;

}

.image-about {
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url(../public/promo.jpg);
    padding: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: cover;
}

.image-contact {
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.5)), url(../public/promo.jpg);
    padding: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: cover;
}



.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
}

.footer {
    background-color: #420621;
    color: #FFF;
    padding: 20px 0;
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
}

.footer-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.footer-logo {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.footer-disc {
    font-size: 14px;
    margin-bottom: 20px;
    color: #AAA;
}

.footer-disc span {
    color: #FFF;
    font-weight: bold;
}

.footer-nav {
    margin-bottom: 20px;
}

.footer-menu {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.footer-menu li {
    margin: 5px 15px;
}

.footer-menu a {
    color: #BBB;
    text-decoration: none;
    font-size: 14px;
    transition: color 0.3s;
}

.footer-menu a:hover {
    color: #FFF;
}

.footer-mail a {
    font-size: 24px;
    color: #BBB;
    display: inline-block;
    transition: color 0.3s;
}

.footer-mail a:hover {
    color: #FFF;
}

.footer-copyright {
    font-size: 12px;
    color: #777;
}


@media(max-width:1024px) {
    .main {
      padding-top: 7rem;
  }
    .header-wrapper {
      flex-direction: column;
      gap: 1em;
    }
  
    .game-wrapper {
      padding: 0 5% 0 5%;
  }
  
    .footer-disc {
      font-size: 20px;
    }
  
    .footer-menu a {
      font-size: 20px;
    }
  
  }
  
  @media(max-width:768px) {
    .header-logo a{
      font-size: 28px;
    }
  
    .header-menu {
      gap: 5px;
    }
  
    .header-menu a{
      font-size: 18px;
    }
  
    .promo-wrapper {
      flex-direction: column;
    }
  
    .promo-content {
      flex-direction: column;
    }
  
    .promo-title {
      font-size: 32px;
    }
  
    .promo-txt {
      font-size: 28px;
    }
  
    .promo-btn {
      font-size: 20px;
    }
  
    .game-txt {
      bottom: -18%;
    }
  
    .game-wrapper {
      grid-template-columns: repeat(1, 1fr);
    }
  
  
    .footer-logo {
      font-size: 28px;
    }
  
    .footer-disc {
      font-size: 24px;
    }
  
    .footer-menu {
      flex-direction: column;
      text-align: center;
    }
  
    .footer-menu a {
      font-size: 18px;
    }
  
    .wrapper {
      padding: 1em 0;
      display: flex;
      flex-direction: column;
    }
  
    .games {
      flex-direction: column;
    }
  
    .footer-pages {
      gap: 20px;
    }
  
    .wrapper img{
      width: 70%;
      height: 70%;
    }
  
  
  }
  .wrapper {
    padding: 2rem;
    display: flex;
    align-items: center;
    color: var(--text);
    gap: 3rem;
  }
  
  .wrapper-policy {
    padding: 2em 20px;
    color: #fff;
  }
  
  .wrapper img {
    width: 30%;
    height: 30%;
    object-fit: cover;
  }
  
  .wrapper p {
    font-size: 24px;
    margin-bottom: 2.5em;
  }
  
  .wrapper h1 {
    font-size: 36px;
    margin-bottom: 1em;
  }
  
  .wrapper a {
    color: var(--text);
  }